import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import FHButton from "../FHButton";
import FHAuthContainer from "../FHAuthContainer";
import { useForm } from "react-hook-form";
import FHInputField from "../FHInputField";
import FHLink from "../FHLink";
import FHClose from "../FHClose";
import useNewsLetterSignUpService from "src/services/newsletter-signup-service";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: 600,
    },
    "& form": {
      backgroundColor: `${theme.palette.warning.dark}08`,
    },
  },
  descriptionContainer: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
  },
  actionsContainer: {
    padding: theme.spacing(0, 4, 4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2, 2),
    },
    "& button": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      minWidth: 250,
    },
  },
  closeBtn: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  inputField: {
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
  bottomText: {
    fontSize: 14,
  },
  infoText: {
    fontSize: 14,
    "& a": {
      marginLeft: 2,
    },
  },
  privacyPolicyLabel: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",
  },
  privacyPolicyFrame: {
    height: "459px",
    width: "499px",
  },
}));

type SubscribeModalProps = {
  title: string;
  emailLabel: string;
  firstNameLabel: string;
  receiveMessageByEmail?: string;
  moreInfoText?: string;
  submitLabel: string;
  privacyLinkUrl?: string;
  privacyLinkLabel?: string;
};

export const SubscribeModal: React.FC<SubscribeModalProps> = ({
  title,
  emailLabel,
  firstNameLabel,
  receiveMessageByEmail,
  moreInfoText,
  submitLabel,
  privacyLinkUrl,
  privacyLinkLabel,
}: PropsWithChildren<SubscribeModalProps>) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const { newsletterSignUp, success } = useNewsLetterSignUpService();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const closeModal = () => {
    setModalOpen(false);
    localStorage.setItem("dismissedOrSubscribedToMarketing", "true");
  };

  const onSubmit = (form: any) => {
    newsletterSignUp({
      variables: {
        request: {
          email: email ? email : "",
          forenames: firstName ? firstName : "",
          sourceType: "Web",
          sourceDetail: "Popup",
        },
      },
    });
    localStorage.setItem("dismissedOrSubscribedToMarketing", "true");
  };

  return (
    <Dialog
      className={classes.root}
      open={modalOpen}
      onClose={() => closeModal()}
      aria-labelledby="Subscribe-Modal-Title"
      aria-describedby="Subscribe-Modal-Description"
    >
      <FHAuthContainer onSubmit={handleSubmit(onSubmit)}>
        <FHClose
          className={classes.closeBtn}
          onCloseButtonClick={() => closeModal()}
          color="primary"
        />
        <DialogContent className={classes.descriptionContainer}>
          {success ? (
            <p>
              Keep an eye on your inbox for the latest forest news & offers. (Be
              sure to check your junk and promotional folders too). We hope to
              see you in the forest soon.
            </p>
          ) : (
            <>
              {showPrivacyPolicy ? (
                <iframe
                  className={classes.privacyPolicyFrame}
                  src={privacyLinkUrl}
                />
              ) : (
                <>
                  <Typography variant="h3" className={classes.title}>
                    {title}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FHInputField
                        name={"firstname"}
                        label={firstNameLabel}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue={firstName}
                        onInputChange={(event) =>
                          setFirstName(event.target.value)
                        }
                        className={classes.inputField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FHInputField
                        name={"email"}
                        label={emailLabel}
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\S+@\S+\.\S+$/,
                        }}
                        defaultValue={email}
                        onInputChange={(event) => setEmail(event.target.value)}
                        className={classes.inputField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {receiveMessageByEmail && (
                        <Typography
                          variant="body2"
                          className={classes.bottomText}
                        >
                          <strong>{receiveMessageByEmail}</strong>
                        </Typography>
                      )}
                      {moreInfoText && (
                        <Typography
                          variant="body2"
                          className={classes.infoText}
                        >
                          <span>{moreInfoText}</span>
                          <span
                            className={classes.privacyPolicyLabel}
                            onClick={() => setShowPrivacyPolicy(true)}
                          >
                            {privacyLinkLabel}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        {!success && !showPrivacyPolicy && (
          <DialogActions className={classes.actionsContainer}>
            <Grid container spacing={2}>
              <Grid item>
                <FHButton
                  fhStyle="primary"
                  fullWidth
                  type={"submit"}
                  disabled={!isValid}
                >
                  {submitLabel}
                </FHButton>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </FHAuthContainer>
    </Dialog>
  );
};
