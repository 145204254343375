import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormHelperText, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { handleWhiteSpacePrefix } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  inputBaseRoot: {
    "& fieldset": {
      border: `2px solid ${theme.palette.action.disabled}`,
      borderRadius: theme.shape.borderRadius,
      padding: "0 14px",
      "& legend": {
        fontSize: 16,
        lineHeight: "24px",
      },
    },
    "&:hover": {
      "& fieldset": {
        border: `2px solid ${theme.palette.grey[300]}`,
      },
    },
    "&:focus": {
      "& fieldset": {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    "&$inputBaseError": {
      "& fieldset": {
        border: `2px solid ${theme.palette.secondary.dark} !important`,
      },
    },
  },
  inputDisabled: {
    "& fieldset": {
      border: `2px solid ${theme.palette.action.disabled}`,
      backgroundColor: theme.palette.divider,
    },
  },
  inputBaseError: {
    "& fieldset": {
      border: `2px solid ${theme.palette.secondary.dark}`,
    },
  },
  inputRoot: {
    fontFamily: theme.typography.h2.fontFamily,
    padding: theme.spacing(1.8, 2, 1.9, 2),
    fontSize: 16,
    lineHeight: "24px",
  },
  labelRoot: {
    marginBottom: theme.spacing(1),
    transform: "translate(20px, 18px) scale(1)",
    color: theme.palette.text.disabled,
    "&$labelShrink": {
      transform: "translate(20px, -10px) scale(1)",
      color: theme.palette.text.primary,
    },
    "&$labelError": {
      color: theme.palette.secondary.dark,
    },
    "&$labelFocused": {
      color: theme.palette.primary.main,
    },
  },
  labelShrink: {
    transform: "translate(20px, -8px) scale(1)",
  },
  errorHelpContainer: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  labelError: {
    "&$labelShrink": {
      color: theme.palette.secondary.dark,
    },
  },
  labelFocused: {},
}));

export type FHInputFieldProps = {
  name: string;
  label: string;
  onInputChange?: (event: any) => void;
  validationFailure?: string;
  help?: string;
  rules?: object;
  control?: any;
  errorRef?: any;
  defaultValue?: string;
  className?: string;
  errorClassName?: string;
  noOutlineHighlight?: boolean;
  endAdornment?: any;
  type?: any;
  disabled?: boolean;
};

export const FHInputField: React.FC<FHInputFieldProps> = ({
  label,
  name,
  onInputChange = () => {},
  validationFailure,
  help,
  control,
  rules,
  type,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  endAdornment,
  disabled,
}: PropsWithChildren<FHInputFieldProps>) => {
  const classes = useStyles();
  const [showHelpMessage, setShowHelpMessage] = useState(false);
  return (
    <div data-testid={"inputRoot"}>
      <Controller
        {...{ name, control, rules, defaultValue }}
        render={({ field: { ref, name, value, onChange, onBlur } }) => (
          <TextField
            fullWidth
            inputRef={ref}
            variant="outlined"
            type={type}
            onKeyPress={handleWhiteSpacePrefix}
            {...{ name, label, className }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                shrink: classes.labelShrink,
                error: classes.labelError,
                focused: classes.labelFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.inputBaseRoot,
                error: classes.inputBaseError,
                input: classes.inputRoot,
                disabled: classes.inputDisabled,
              },
              endAdornment: endAdornment,
            }}
            onChange={(event) => {
              onChange(event);
              if (onInputChange) {
                event.persist();
                onInputChange(event);
              }
            }}
            value={disabled ? "" : value}
            disabled={disabled}
            onFocus={() => setShowHelpMessage(true)}
            error={errorRef}
          />
        )}
      />
      {(!!errorRef || showHelpMessage) && (
        <div className={classes.errorHelpContainer}>
          {!!validationFailure && !!errorRef && (
            <Typography
              className={errorClassName}
              data-testid={"inputErrorMessage"}
            >
              {validationFailure}
            </Typography>
          )}

          {help && (showHelpMessage || !!errorRef) && (
            <FormHelperText data-testid={"inputHelpMessage"}>
              {help}
            </FormHelperText>
          )}
        </div>
      )}
    </div>
  );
};

export default FHInputField;
