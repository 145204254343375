import { BookingSummary_S } from "@generated/types";
import moment from "moment";
import TagManager from "react-gtm-module";

type BasketDetailsDataLayer = {
  event: string;
  productID: string;
  bookingUrl: string;
  startDate: string;
  endDate: string;
  price: string;
  currency: string;
};

export const postBasketSummary = (cabin: BookingSummary_S) => {
  const basketDetails: BasketDetailsDataLayer = {
    event: "basketDetails",
    productID: cabin?.productID,
    bookingUrl: `bid=${cabin?.bookingId}&l=${cabin?.locationId}&d=${
      cabin?.noOfNights
    }&sd=${moment(cabin?.reservationStartDate).format("YYYY/MM/DD")}`,
    startDate: moment(cabin?.reservationStartDate).format("DD/MM/YYYY"),
    endDate: moment(cabin?.reservationEndDate).format("DD/MM/YYYY"),
    price: cabin?.preDiscountCabinPrice,
    currency: "GBP",
  };
  TagManager.dataLayer({
    dataLayer: basketDetails,
  });
  callFROnPageChangeEvent();
};

export const callFROnPageChangeEvent = () => {
  if (
    typeof $TB !== "undefined" &&
    $TB.hooks &&
    typeof $TB.hooks.fireEvent === "function"
  ) {
    $TB.hooks.fireEvent("pageChange", null, { sendBeacon: true });
  }
};
