import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
    boxShadow: `inset 0px 0px 0px 2px ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(0.4),
    minWidth: "auto",
    width: 31,
    height: 31,
    "& svg": {
      fill: "currentColor",
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
      boxShadow: `inset 0px 0px 0px 4px ${theme.palette.divider}`,
    },
  },
  primary: {
    color: theme.palette.info.dark,
    boxShadow: `inset 0px 0px 0px 2px ${theme.palette.info.dark}`,
    "&:hover, &:focus, &:active": {
      boxShadow: `inset 0px 0px 0px 4px ${theme.palette.info.dark}`,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
}));

export type FHCloseProps = {
  icon?: JSX.Element;
  color?: "primary" | "secondary";
  onCloseButtonClick?: () => void;
  className?: string;
};

export const FHClose: React.FC<FHCloseProps> = ({
  icon = <CloseRoundedIcon />,
  color,
  onCloseButtonClick,
  className,
}: PropsWithChildren<FHCloseProps>) => {
  const classes = useStyles();

  return (
    <Button
      className={classNames(classes.root, className, {
        [classes.primary]: color === "primary",
        [classes.secondary]: color === "secondary",
      })}
      onClick={onCloseButtonClick}
    >
      {icon}
    </Button>
  );
};
export default FHClose;
