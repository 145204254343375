import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { FHButton } from "../FHButton";
import FHLink from "../FHLink";
import classNames from "classnames";

const transition = "opacity 500ms";
const useStyles = (props: FHAuthContainerStyleProps) =>
  makeStyles((theme) => ({
    root: {
      position: "relative",
      top:
        typeof props.topOverlay === "boolean"
          ? props.topOverlay
            ? -200
            : 0
          : props.topOverlay,
      zIndex: 1000,
      maxWidth: props.maxWidth,
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      "& .MuiPaper-root": {
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
          boxShadow: "none",
          borderRadius: "revert",
          padding: theme.spacing(1),
        },
      },
    },
    wrapper: props.disabled
      ? {
          opacity: 0.5,
          pointerEvents: "none",
          transition,
          [theme.breakpoints.down("sm")]: {
            marginTop: 0,
          },
        }
      : {
          transition,
          marginTop: 0,
        },
    content: {
      maxWidth: "100%",
    },
    contentGroup: {
      paddingTop: theme.spacing(3),
    },
    title: {},
    submit: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
    },
    footerAction: {
      textAlign: "center",
    },
    footerActionForEmail: {
      paddingTop: theme.spacing(2),
    },
    titleGroup: {
      display: "flex",
      "& a": {
        marginLeft: "auto",
      },
    },
    continueButton: {
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:focus": {
        textDecoration: "underline !important",
      },
    },
    continueButtonDisabled: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      "&:focus": {
        textDecoration: "none !important",
      },
    },
  }))();

export type FHAuthContainerStyleProps = {
  maxWidth?: string | number;
  topOverlay?: boolean | string | number;
  disabled?: boolean;
};

export type FHAuthContainerProps = {
  title?: string;
  subtitle?: string;
  submitBtnText?: string;
  submitBtnDisabled?: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  linkText?: string;
  onLinkClick?: () => void;
  linkUrl?: string;
  isEmailVerificationForm?: boolean;
  isBackLink?: boolean;
  backLinkLabel?: string;
  backLinkUrl?: string;
  onBackLinkClick?: () => void;
  applyUnderliningStylingCheckoutCTAs?: boolean;
} & FHAuthContainerStyleProps;

export const FHAuthContainer: React.FC<
  PropsWithChildren<FHAuthContainerProps>
> = ({
  title,
  subtitle,
  submitBtnText,
  disabled,
  submitBtnDisabled,
  onSubmit,
  linkText,
  onLinkClick,
  linkUrl,
  children,
  maxWidth = 600,
  topOverlay = false,
  isEmailVerificationForm,
  isBackLink,
  backLinkLabel,
  backLinkUrl,
  onBackLinkClick,
  applyUnderliningStylingCheckoutCTAs,
}: PropsWithChildren<FHAuthContainerProps>) => {
  const classes = useStyles({ disabled: !!disabled, maxWidth, topOverlay });
  return (
    <div className={classes.root}>
      <form {...{ onSubmit }}>
        <Grid
          container
          spacing={isEmailVerificationForm ? 0 : 2}
          className={classes.wrapper}
        >
          {(title || subtitle) && (
            <Grid item xs={12}>
              {(title || isBackLink) && (
                <div className={classes.titleGroup}>
                  {title && (
                    <Typography variant={"h2"} className={classes.title}>
                      {title}
                    </Typography>
                  )}
                  {isBackLink && (
                    <FHLink
                      title={backLinkLabel}
                      URL={backLinkUrl || ""}
                      onLinkClick={() => onBackLinkClick && onBackLinkClick()}
                    />
                  )}
                </div>
              )}
              {subtitle && (
                <Typography component={"div"}>
                  <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                </Typography>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classNames(classes.content, {
              [classes.contentGroup]: isEmailVerificationForm,
            })}
          >
            {children}
          </Grid>
          {submitBtnText && (
            <Grid xs={12} item className={classes.footerAction}>
              <FHButton
                fhStyle="primary"
                type="submit"
                disabled={submitBtnDisabled}
                fullWidth={true}
                classes={{
                  root:
                    applyUnderliningStylingCheckoutCTAs &&
                    classes.continueButton,
                  disabled:
                    applyUnderliningStylingCheckoutCTAs &&
                    classes.continueButtonDisabled,
                }}
              >
                {submitBtnText}
              </FHButton>
            </Grid>
          )}
          {linkText && (linkUrl || onLinkClick) && (
            <Grid
              item
              xs={12}
              className={classNames(classes.footerAction, {
                [classes.footerActionForEmail]: isEmailVerificationForm,
              })}
            >
              <FHLink
                title={linkText}
                URL={linkUrl || ""}
                onLinkClick={() => onLinkClick && onLinkClick()}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default FHAuthContainer;
